// Self-destructing controller: see https://boringrails.com/articles/self-destructing-stimulus-controllers/

import { ApplicationController } from 'stimulus-use';

export default class extends ApplicationController {
  connect() {
    const annotationElement = this.element.previousElementSibling;
    const elementToFocusId = annotationElement.dataset.annotationFocusValue;
    if (elementToFocusId) {
      const elementToFocus = document.getElementById(elementToFocusId);
      if (elementToFocus) {
        try {
          const end = elementToFocus.value.length;
          elementToFocus.focus();
          elementToFocus.setSelectionRange(end, end);
        } catch {
          // Do nothing
        } finally {
          elementToFocus.focus();
        }
      }
    }
    this.element.remove();
  }
}
