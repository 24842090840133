import { Controller } from '@hotwired/stimulus';
import { useTransition } from 'stimulus-use';

export default class extends Controller {
  static get targets() {
    return ['button', 'menu'];
  }

  connect() {
    window.addEventListener('turbo:morph', () => {
      this.enable();
    });
    this.enable();
  }

  enable() {
    useTransition(this, {
      element: this.menuTarget,
    });
  }

  move() {
    if (this.menuTarget.classList.contains('hidden')) {
      this.enter();
    } else {
      this.leave();
    }
  }
}
