import { ApplicationController } from 'stimulus-use';
import { patch } from '@rails/request.js';

export default class extends ApplicationController {
  async handleChange(event) {
    this.focusNextInput(event.target);
    const form = this.element.closest('form');
    const url = form.action;
    const formData = new FormData(form);

    // If food is not selected, do not send the request
    if (formData.get('annotation_item[food_id]') === '') return;

    await patch(url, {
      body: formData,
      responseKind: 'turbo-stream',
    });
  }

  handleFocus(event) {
    // This fixes an issue occuring in Firefox when the input is located
    // in a draggable element (see https://bugzilla.mozilla.org/show_bug.cgi?id=1189486)
    if (navigator.userAgent.includes('Firefox')) {
      const { target } = event;
      const draggable = target.closest('[draggable="true"]');
      if (draggable) {
        draggable.draggable = false;
        const end = target.value.length;
        target.setSelectionRange(end, end);
      }
    }
  }

  handleBlur(event) {
    if (navigator.userAgent.includes('Firefox')) {
      const { target } = event;
      const draggable = target.closest('[draggable="false"]');
      if (draggable) {
        draggable.draggable = true;
      }
    }
  }

  focusNextInput(element) {
    if (element.tomselect == null) {
      return;
    }

    const nextInput = element
      .closest('.annotation-item')
      .querySelector('input[name="annotation_item[consumed_quantity]"]');
    if (nextInput) {
      nextInput.focus();
    }
  }
}
